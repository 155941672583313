html{
    font-size: 62.5%;
    -webkit-text-size-adjust:100%;
}

 body{
    height: 100vh;
    margin: 0;
}

#root {
    height: 100%;
}

* {
    font-family: 'Montserrat';
  }
h1{
    font-size: 2.5rem;
}
h2{
    font-size: 2rem;
}
h3{
    font-size: 1.6rem;
}
h1,h2,h3{
    font-weight: bold;
}

li, p {
    margin: 0;
    font-size: 1.4rem;
}

.react-datepicker-wrapper {
    width: 100%;
}